import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Mark notification read via ajax
  async markRead (event) {
    const url = event.currentTarget.dataset.readUrlTarget;
    let csrfToken = $('meta[name="csrf-token"]').attr('content');

    $.ajax({ type: 'patch', url: url, headers: { 'X-CSRF-Token': csrfToken } })
  }
}
